export const offer_display_section_props = {
    sx: {
        width: '100%',
        height: 'auto',
        flexFlow: 'column nowrap',
        margin: '0 0 5rem 0'
    }
}

export const offer_display_heading_props = {
    as: 'h1',
    background_text: 'Oferta',
    margin: '2rem auto 3.5rem auto'
}