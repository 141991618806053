import { image_sizes } from './image_sizes'

const icon_size = icon => image_sizes.filter(img => img.name === icon.replace('.png', ''))[0];

// PROPS

export const offer_display_item_props = side => ({
    sx: {
        width: '100%',
        flexFlow: ['column nowrap', `${side === 'right' ? 'row-reverse nowrap' : 'row nowrap'}`],
        margin: '0.5rem 0 5rem 0',
        alignSelf: side === 'right' ? 'flex-end' : null
    }
})

export const offer_icon_container_props = {
    sx: {
        position: 'relative',
        width: ['100%', '60%', '70%'],
        marginTop: [0, '2.5rem'],
        marginBottom: ['5rem', null, null],
        height: [160, 160, 200]
    }
}

export const offer_icon_background_props = side => {
    const icon_position = side === 'right' ? { right: ['1.25rem', '1.25rem', 0] } : { left: ['1.25rem', '1.25rem', 0] };

    return {
        sx: {
            width: [160, 160, 200],
            height: '100%',
            margin: ['0 auto', 0, 0],
            background: 'linear-gradient(180deg, #97021B 31.25%, #C60122 100%)',
            boxShadow: '0px 4px 8px rgba(0,0,0,0.05)',
            position: 'absolute',
            ...icon_position
        }
    }
}

export const icon_props = (icon, data) => ({
    sx: {
        width: icon_size(icon)?.width,
        height: icon_size(icon)?.height,
        position: 'absolute',
        bottom: '-2.5rem',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    src: `${data?.allFile?.edges?.filter(file => file?.node?.name === icon.replace('.png', ''))[0]?.node?.publicURL || ''}`,
    alt: icon
})

export const offer_info_container_props = {
    sx: {
        width: '100%', position: 'relative', flexFlow: 'column nowrap', minHeight: 300
    }
}

const border_props = (position, width, height, transform, display) => ({
    position,
    width,
    height,
    transform,
    display
})

export const border_top_props = side => border_props(
    {
        top: side === 'right' ? [0, null, null] : 0,
        bottom: side === 'right' ? [null, 0, 0] : null,
        left: side === 'right' ? 0 : null,
        right: side === 'right' ? null : 0
    },
    '75%',
    10,
    { transform: side === 'right' ? null : 'rotate(180deg)' },
    null
)

export const border_side_props = side => border_props(
    {
        top: '1px',
        left: side === 'right' ? 0 : null,
        right: side === 'right' ? null : 0
    },
    10,
    '55%',
    { transform: 'rotate(180deg)' },
    { display: ['none', 'none', 'inherit'] }
)

export const offer_info_title_props = side => ({
    as: 'h2',
    maxWidth: 240,
    fontSize: [20, 20, 22],
    fontWeight: 700,
    margin: '3.5rem 0 0 0',
    textAlign: 'left',
    padding: ['0 1.25rem', '0 1.25rem', `${side === 'right' ? '0 0 0 3.5rem' : '0'}`]
})

export const offer_info_text_props = side => ({
    as: 'p',
    fontSize: [16, 16, 18],
    padding: [
        `0 1.25rem`,
        `0 1.25rem`,
        `0 ${side === 'right' ? '0' : '3.5rem'} 1.5rem ${side === 'left' ? '0' : '3.5rem'}`
    ],
    margin: '2rem 0 0 0'
})

export const offer_info_button_props = (side, navigate, slug) => ({
    position: {
        position: 'relative',
        button: 0,
        alignSelf: side === 'right' ? 'flex-end' : 'flex-start'
    },
    padding: [
        '0 1.25rem',
        '0 1.25rem',
        `0 ${side === 'right' ? '0' : '3.5rem'} 0 ${side === 'left' ? '0' : '3.5rem'}`],
    margin: '2rem 0 0 0',
    onClick: () => navigate(`${slug}`)
})