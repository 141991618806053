import React from 'react'

// COMPONENTS
import SEO from './../components/templates/seo';
import OfferDisplaySection from './../components/organisms/offerDisplaySection';

export default function Services(props) {
    return (
        <>
            <SEO title='Oferta' />
            <OfferDisplaySection />
        </>
    )
}
