import React from 'react';
import data from './../../../../oferta.json'
import shortid from 'shortid'

// STYLES
import {
  offer_display_section_props,
  offer_display_heading_props
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import OfferDisplayItem from './../../molecules/offerDisplayItem';

export default function OfferDisplaySection() {

  const offer_display_items = data.map((el, i) =>
    <OfferDisplayItem 
    key={shortid.generate()}
    side={i % 2 === 0 ? 'left' : 'right'}
    slug={el.slug} title={el.title}
    icon={el.icon}
    short_description={el.short_description} />
  )

  return (
    <Section {...offer_display_section_props}>
        <Heading {...offer_display_heading_props}>Nasza oferta</Heading>
        {offer_display_items}
    </Section>
  );
}
