export const image_sizes = [
    {
        name: 'wkłady',
        width: [116.27, 116.27, 145.34],
        height: [188.82, 188.82, 236.02]
    },
    {
        name: 'piecyki_wolnostojace',
        width: [111.63, 111.63, 139.53],
        height: [223.26, 223.26, 279.07]
    },
    {
        name: 'kominki_gazowe',
        width: [148.84, 148.84, 186.05],
        height: [138.93, 138.93, 173.66]
    },
    {
        name: 'kominki_kaflowe',
        width: [74.42, 74.42, 93.02],
        height: [189.41, 189.41, 236.76]
    },
    {
        name: 'piece_na_pellet',
        width: [93.02, 93.02, 116.28],
        height: [171.2, 171.2, 214]
    },
    {
        name: 'biokominki',
        width: [200, 200, 220],
        height: [85.71, 85.71, 94.29]
    },
    {
        name: 'kuchnie_kaflowe',
        width: [139.53, 139.53, 174.42],
        height: [119.07, 119.07, 148.84]
    },
    {
        name: 'kominki_elektryczne',
        width: [148.84, 148.84, 186.05],
        height: [89.82, 89.82, 112.27]
    },
    {
        name: 'kominy_lekkie',
        width: [55.81, 55.81, 69.77],
        height: [196.16, 196.16, 245.2]
    },
    {
        name: 'materiały_wykonczeniowe',
        width: [148.84, 148.84, 186.05],
        height: [133.6, 133.6, 167]
    },
    {
        name: 'materiały_instalacyjne',
        width: [139.53, 139.53, 174.42],
        height: [191.21, 191.21, 239.02]
    },
    {
        name: 'opał',
        width: [148.84, 148.84, 186.05],
        height: [91.85, 91.85, 114.82]
    },
]